import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './Loading.module.css';

const Loading = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return createPortal(
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 h-full w-full bg-black/90 text-white-background text-3xl flex items-center justify-center">
      <div className={styles.ldsgrid}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>,
    document.body,
  );
};

export default Loading;
