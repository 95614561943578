import { RouterProvider } from 'react-router-dom';
import router from './constants/routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Suspense, useCallback, useLayoutEffect } from 'react';
import { database } from './utils/database';
import { Loading } from './components';
import SessionProvider from './providers/SessionProvider';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { CACHE_SEVEN_DAYS } from './constants/variables';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      cacheTime: CACHE_SEVEN_DAYS, // 7 days cache time
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie, removeCookie] = useCookies();

  const refreshAccessToken = useCallback(async () => {
    try {
      const { error } = await database.auth.refreshSession();

      if (error) {
        removeCookie('access_token');
        removeCookie('refresh_token');
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error refreshing access_token:', error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSession = useCallback(async () => {
    try {
      const { data, error } = await database.auth.getSession();

      if (!data.session) {
        refreshAccessToken();
        return;
      }

      if (error) {
        removeCookie('access_token');
        removeCookie('refresh_token');
        return;
      }

      setCookie('access_token', data.session?.access_token, {
        maxAge: data.session?.expires_in,
        path: '/',
      });
      setCookie('refresh_token', data.session?.refresh_token, {
        path: '/',
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error getting session:', error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    getSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSession]);

  return (
    <Suspense fallback={<Loading />}>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <SessionProvider>
            <RouterProvider router={router} />
            <Toaster />
          </SessionProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </Suspense>
  );
};

export default App;
