import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { Session, User } from '@supabase/supabase-js';

interface SessionState {
  session: Session | null;
  user?: User;
  setSession: (name: Session | null) => void;
}

export const useSessionStore = create<SessionState>()(
  devtools(
    (set) => ({
      session: null,
      user: undefined,
      setSession: (session) => set({ session, user: session?.user }),
    }),
    {
      name: 'session-storage',
    },
  ),
);
