import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

const Layout = React.lazy(() => import('../layouts/Layout/Layout'));
const ProjectPageLayout = React.lazy(
  () => import('../layouts/ProjectPageLayout/ProjectPageLayout'),
);
const DesignSystem = React.lazy(
  () => import('../pages/DesignSystem/DesignSystem'),
);
const Auth = React.lazy(() => import('../pages/Auth/Auth'));
const AboutNaming = React.lazy(
  () => import('../pages/Homepage/pages/AboutNaming/AboutNaming'),
);
const ClosestDefinition = React.lazy(
  () => import('../pages/Homepage/pages/ClosestDefinition/ClosestDefinition'),
);
const Homepage = React.lazy(() => import('../pages/Homepage/Homepage'));
const CompanyName = React.lazy(
  () => import('../pages/Homepage/pages/CompanyName/CompanyName'),
);
const Email = React.lazy(() => import('../pages/Homepage/pages/Email/Email'));
const MyProjects = React.lazy(() => import('../pages/MyProjects/MyProjects'));
const Appearance = React.lazy(
  () => import('../pages/Homepage/pages/Appearance/Appearance'),
);
const NoNameYet = React.lazy(
  () => import('../pages/Homepage/pages/NoNameYet/NoNameYet'),
);
const MyTrademarks = React.lazy(
  () => import('../pages/Project/pages/MyTrademarks/MyTrademarks'),
);
const Name = React.lazy(() => import('../pages/Project/pages/Name/Name'));
const NamesShortlist = React.lazy(
  () => import('../pages/Project/pages/NamesShortlist/NamesShortlist'),
);
const ProjectPage = React.lazy(
  () => import('../pages/Project/pages/ProjectPage/ProjectPage'),
);
const AvailableDomains = React.lazy(
  () =>
    import(
      '../pages/Project/pages/NamesShortlist/pages/AvailableDomains/AvailableDomains'
    ),
);
const TMResult = React.lazy(
  () => import('../pages/Project/pages/Name/pages/Result/Result'),
);
const MyDomains = React.lazy(
  () => import('../pages/Project/pages/MyDomains/MyDomains'),
);

const LoadingScreen = React.lazy(() => import('../pages/Loading/Loading'));

type Route = {
  path: string;
  element: JSX.Element;
};

export const routes: Route[] = [
  {
    path: '/',
    element: (
      <Layout>
        <Homepage />
      </Layout>
    ),
  },
  {
    path: '/about-naming',
    element: (
      <Layout>
        <AboutNaming />
      </Layout>
    ),
  },
  {
    path: '/choose-closest-definition',
    element: (
      <Layout>
        <ClosestDefinition />
      </Layout>
    ),
  },
  {
    path: '/company-name',
    element: (
      <Layout>
        <CompanyName />
      </Layout>
    ),
  },
  {
    path: '/no-name-yet',
    element: (
      <Layout>
        <NoNameYet />
      </Layout>
    ),
  },
  {
    path: '/appearance',
    element: (
      <Layout>
        <Appearance />
      </Layout>
    ),
  },
  {
    path: '/email',
    element: (
      <Layout>
        <Email />
      </Layout>
    ),
  },
  {
    path: '/design-system',
    element: (
      <Layout>
        <DesignSystem />
      </Layout>
    ),
  },
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/my-projects',
    element: (
      <Layout>
        <MyProjects />
      </Layout>
    ),
  },
  {
    path: '/my-projects/:projectId',
    element: (
      <Layout>
        <ProjectPageLayout>
          <ProjectPage />
        </ProjectPageLayout>
      </Layout>
    ),
  },
  {
    path: '/my-projects/:projectId/names-shortlist',
    element: (
      <Layout>
        <ProjectPageLayout>
          <NamesShortlist />
        </ProjectPageLayout>
      </Layout>
    ),
  },
  {
    path: '/my-projects/:projectId/names-shortlist/:nameId',
    element: (
      <Layout>
        <ProjectPageLayout>
          <Name />
        </ProjectPageLayout>
      </Layout>
    ),
  },
  {
    path: '/my-projects/:projectId/names-shortlist/:nameId/:identifier',
    element: (
      <Layout>
        <ProjectPageLayout>
          <TMResult />
        </ProjectPageLayout>
      </Layout>
    ),
  },
  {
    path: '/my-projects/:projectId/names-shortlist/:nameId/available-domains',
    element: (
      <Layout>
        <ProjectPageLayout>
          <AvailableDomains />
        </ProjectPageLayout>
      </Layout>
    ),
  },
  {
    path: '/my-projects/:projectId/my-trademarks',
    element: (
      <Layout>
        <ProjectPageLayout>
          <MyTrademarks />
        </ProjectPageLayout>
      </Layout>
    ),
  },
  {
    path: '/my-projects/:projectId/my-domains',
    element: (
      <Layout>
        <ProjectPageLayout>
          <MyDomains />
        </ProjectPageLayout>
      </Layout>
    ),
  },
  {
    path: '/loading',
    element: <LoadingScreen />,
  },
];

const router = createBrowserRouter(routes);

export default router;
