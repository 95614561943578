import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { TODO } from '../types';

interface NameState {
  names: TODO[];
  addName: (name: TODO) => void;
  setNames: (names: TODO[]) => void;
  filterKey: string;
  setFilterKey: (filterKey: string) => void;
  selectedName: TODO | null;
  setSelectedName: (selectedName: TODO) => void;
}

export const useNameStore = create<NameState>()(
  devtools(
    (set) => ({
      names: [],
      addName: (name) => set((state) => ({ names: [...state.names, name] })),
      setNames: (names) => set({ names }),
      filterKey: '',
      setFilterKey: (filterKey) => set({ filterKey }),
      setSelectedName: (selectedName: TODO) => set({ selectedName }),
      selectedName: null,
    }),
    {
      name: 'names-storage',
    },
  ),
);
