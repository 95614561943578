import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { Database } from '../types/database.types';

// refactor multiple to singular
interface ProjectState {
  selectedProject: Database['public']['Tables']['projects']['Row'] | null;
  setSelectedProject: (
    project: Database['public']['Tables']['projects']['Row'],
  ) => void;
}

export const useProjectStore = create<ProjectState>()(
  devtools(
    (set) => ({
      selectedProject: null,
      setSelectedProject: (project) => set({ selectedProject: project }),
    }),
    {
      name: 'projects-storage',
    },
  ),
);
