import { forwardRef, useState } from 'react';
import { TODO } from '../../types';
import { FaEye } from 'react-icons/fa6';
import { FaEyeSlash } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';

interface IInput {
  register?: TODO;
}

type TInputProps = IInput & React.InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      name,
      placeholder,
      className,
      disabled,
      defaultValue,
      onChange,
      register,
      type = 'text',
      required = false,
    },
    ref,
  ) => {
    const [showPasswordState, setShowPasswordState] = useState(false);

    const toggleShowPassword = () => setShowPasswordState(!showPasswordState);
    const renderType = type === 'password' && showPasswordState ? 'text' : type;

    return (
      <div className="relative">
        <input
          ref={ref}
          type={renderType}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={disabled}
          className={twMerge(
            `bg-[#F9FAFB] text-text w-full md:w-[588px] py-5 px-3 rounded-xl border border-[#F9FAFB] outline-primary transition placeholder:text-gray-600 hover:border-primary disabled:cursor-not-allowed disabled:border-0 relative`,
            className,
          )}
          {...register(name, { required: required })}
        />
        {type === 'password' && (
          <div className="absolute top-2/4 -translate-y-2/4 right-9">
            {showPasswordState ? (
              <FaEyeSlash size={24} onClick={toggleShowPassword} />
            ) : (
              <FaEye size={24} onClick={toggleShowPassword} />
            )}
          </div>
        )}
      </div>
    );
  },
);

export default Input;
