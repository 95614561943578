import { useEffect } from 'react';
import { database } from '../utils/database';
import router from '../constants/routes';

import { useSessionStore } from '../store/SessionStore';

const SessionProvider = ({ children }: { children?: React.ReactNode }) => {
  const { setSession } = useSessionStore();

  useEffect(() => {
    database.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = database.auth.onAuthStateChange((event, session) => {
      const location = window.location.pathname;
      if (event === 'SIGNED_IN' && location === '/auth') {
        router.navigate('/my-projects');
      }

      if (event === 'SIGNED_OUT') {
        router.navigate('/');
      }

      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, [setSession]);

  return children;
};

export default SessionProvider;
