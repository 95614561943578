import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';

export type TResponsesKeys =
  | 'username'
  | 'email'
  | 'companyName'
  | 'type'
  | 'does'
  | 'used'
  | 'called'
  | 'classDefinition'
  | 'animal'
  | 'allTimeFor'
  | 'wentWithBecause'
  | 'rethinkHowTo'
  | 'bringsNewLifeTo'
  | 'hasName'
  | 'about_animal'
  | 'classDescription';

interface MultiStepFormState {
  steps: number;
  activeStep: number;
  backStep: () => void;
  nextStep: () => void;
  responses: Record<TResponsesKeys, string>;
  setResponses: (key: TResponsesKeys, value: string) => void;
  isBackDisabled: boolean;
  setIsBackDisabled: (value: boolean) => void;
  activeNoNameStep: number;
  setActiveNoNameStep: (value: number) => void;
  isNoNamesFlowActive: boolean;
  setIsNoNamesFlowActive: (value: boolean) => void;
  reset: () => void;
}

export const useMultiStepFormStore = create<MultiStepFormState>()(
  devtools(
    persist(
      (set) => ({
        steps: 5,
        activeStep: 0,
        backStep: () => set((state) => ({ activeStep: state.activeStep - 1 })),
        nextStep: () => set((state) => ({ activeStep: state.activeStep + 1 })),
        responses: {
          username: '',
          email: '',
          companyName: '',
          type: '',
          does: '',
          used: '',
          called: '',
          classDefinition: '',
          classDescription: '',
          animal: '',
          allTimeFor: '',
          wentWithBecause: '',
          rethinkHowTo: '',
          bringsNewLifeTo: '',
          hasName: '',
          about_animal: '',
        },
        setResponses: (key, value) =>
          set((state) => ({ responses: { ...state.responses, [key]: value } })),
        isBackDisabled: false,
        setIsBackDisabled: (value) => set({ isBackDisabled: value }),
        activeNoNameStep: 0,
        setActiveNoNameStep: (value) => set({ activeNoNameStep: value }),
        isNoNamesFlowActive: false,
        setIsNoNamesFlowActive: (value) => set({ isNoNamesFlowActive: value }),
        reset: () => {
          set({ activeStep: 0 });
          set({
            responses: {
              username: '',
              email: '',
              companyName: '',
              type: '',
              does: '',
              used: '',
              called: '',
              classDefinition: '',
              classDescription: '',
              animal: '',
              allTimeFor: '',
              wentWithBecause: '',
              rethinkHowTo: '',
              bringsNewLifeTo: '',
              hasName: '',
              about_animal: '',
            },
          });
          set({ isBackDisabled: false });
          set({ activeNoNameStep: 0 });
          set({ isNoNamesFlowActive: false });
        },
      }),
      {
        name: 'multi-step-form-storage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
